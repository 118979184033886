// import Swiper JS + pagination
import Swiper from 'swiper';
import { Pagination, Mousewheel } from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/mousewheel';

const initSlider = ({ sliderBlock }) => {
  const swiperElement = sliderBlock?.querySelector('.swiper');
  if (swiperElement) {
    const pagination = sliderBlock.querySelector('.swiper-pagination');
    // init Swiper:
    // eslint-disable-next-line no-unused-vars
    const swiper = new Swiper(swiperElement, {
      animating: true,
      direction: 'horizontal', // Horizontal scrolling
      mousewheel: true, // Enable mouse wheel control
      pagination: {
        el: pagination,
        clickable: true,
      },
      on: {
        afterInit: function () {
          if (pagination.classList.contains('swiper-pagination-lock')) {
            const swiperWrapperElement = swiperElement.querySelector('.swiper-wrapper');
            swiperWrapperElement.style.justifyContent = 'center';
          }
        },
      },
      // Default parameters
      // configure Swiper to use modules
      modules: [Pagination, Mousewheel],
      slidesPerView: 1,
      spaceBetween: 20,
      breakpoints: {
        // when window width is >= 375px
        375: {
          slidesPerView: 'auto',
        },
      },
    });
  }
};

export const initKeyAdvantagesSlider = ({ sliderBlock }) => {
  initSlider({
    sliderBlock,
  });
};
