import { addedClassOnResize } from '../../utils/utils';

export const initDialog = ({ dialogElement, callback }) => {
  if (dialogElement) {
    const closeButton = dialogElement.querySelector('.icon-menu');

    closeButton && closeButton.addEventListener('click', () => dialogElement.hide());
    addedClassOnResize({
      element: dialogElement,
    });
    callback && callback();
  }
};
