export const initYoutubeLoader = () => {
  function youtubeLoader() {
    let tag = document.createElement('script');

    tag.src = 'https://www.youtube.com/iframe_api';
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    const buttonElements = document.querySelectorAll('.embed-responsive-button');
    if (buttonElements?.length) {
      // let timer;
      for (const buttonElement of buttonElements) {
        buttonElement.addEventListener('click', function (e) {
          let btn = e.currentTarget,
            videoID = btn.dataset.video,
            playerID = btn.dataset.id;

          e.preventDefault();

          // eslint-disable-next-line no-undef
          new YT.Player(playerID, {
            playerVars: {
              controls: 0,
              showinfo: 0,
              disablekb: 1,
              rel: 0,
              playsinline: 1,
            },
            videoId: videoID,
            events: {
              onReady: onPlayerReady,
            },
            // resolve error on local - Failed to execute 'postMessage' on 'DOMWindow': The target origin provided
            // origin: 'http://localhost:3000'
          });
          btn.disabled = true;
        });
      }
    }

    function onPlayerReady(event) {
      let previewElement = document.querySelector('.embed-responsive-preview');

      if (previewElement) {
        previewElement.classList.add('removed');
      }

      event.target.playVideo();
    }
  }
  youtubeLoader();
};
