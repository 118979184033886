export const initTimer = ({ timerElement, endString = 'Jan 01 2025 00:00:00 EST' }) => {
  if (timerElement) {
    const endTextElement = timerElement.querySelector('.timer__text:last-child');
    const hoursElement = timerElement.querySelector('.timer__count_hour');
    const minutesElement = timerElement.querySelector('.timer__count_minutes');
    const daysElement = timerElement.querySelector('.timer__count_days');
    const secondsElement = timerElement.querySelector('.timer__count_seconds');
    const endDate = new Date(endString);
    const end = endDate.getTime();
    endTextElement && (endTextElement.innerText = 'Ends december 1');

    setInterval(function () {
      // Get the current timestamp in UTC
      const nowUTC = Date.now();

      // Get the EST offset in milliseconds
      const estOffset = new Date().getTimezoneOffset() * 60 * 1000 + 5 * 60 * 60 * 1000;
      // 5 hours for EST offset from UTC

      // Adjust for EST
      const nowEST = nowUTC - estOffset;
      const delta = end - nowEST; // milliseconds elapsed since start

      const setInnerHTML = ({ element, value = 0 }) => {
        const valueString = value.toString();
        let first = 0,
          second = 0;
        if (value < 10 && value > 0) {
          second = valueString[0];
        } else if (value > 9) {
          first = valueString[0];
          second = valueString[1];
        }
        if (element) {
          element.innerHTML = `<span>${first}</span><span>${second}</span>`;
        }
      };
      if (delta > 0) {
        const allSeconds = Math.round(delta / 1000);

        const allMinutes = Math.round(allSeconds / 60);

        const allHours = Math.floor(allMinutes / 60);
        const days = Math.floor(allHours / 24);
        const seconds = allSeconds % 60;

        const currentMinutes = allMinutes % allHours;
        const currentHours = allHours % 24;

        setInnerHTML({ element: minutesElement, value: currentMinutes });
        setInnerHTML({ element: hoursElement, value: currentHours });
        setInnerHTML({ element: daysElement, value: days });
        setInnerHTML({ element: secondsElement, value: seconds });
      } else {
        setInnerHTML({ element: minutesElement });
        setInnerHTML({ element: hoursElement });
        setInnerHTML({ element: daysElement });
        setInnerHTML({ element: secondsElement });
      }
    }, 1000); // update about every second
  }
};
