const circles = [
  {
    id: 'AS',
    country: 'Asia',
    price: '$2,456',
    class: 'destinations-map__asia',
    coordElement: 'CN',
    visible: 1,
    link: 'https://flyer-club.com/p/region/asia/',
  },
  {
    id: 'EU',
    country: 'Europe',
    price: '$1,689',
    class: 'destinations-map__europe',
    coordElement: 'UA',
    visible: 1,
    link: 'https://flyer-club.com/p/region/europe/',
  },
  {
    id: 'OC',
    country: 'Oceania',
    price: '$2,839',
    class: 'destinations-map__oceania',
    coordElement: 'AU',
    visible: 1,
    link: 'https://flyer-club.com/p/region/australia-and-oceania/',
  },
  {
    id: 'ET',
    country: 'Africa',
    price: '$2,234',
    class: 'destinations-map__africa',
    coordElement: 'MG',
    visible: 1,
    link: 'https://flyer-club.com/p/region/africa/',
  },
  {
    id: 'NA',
    country: 'US & Canada',
    price: '$1,376',
    class: 'destinations-map__na',
    coordElement: 'CA',
    visible: 1,
    link: 'https://flyer-club.com/p/bestdeals/',
  },
  {
    id: 'SA',
    country: 'South America',
    price: '$1,542',
    class: 'destinations-map__sa',
    coordElement: 'BR',
    visible: 1,
    link: 'https://flyer-club.com/p/region/latin-america/',
  },
  {
    id: 'ME',
    country: 'Middle East',
    price: '$1,339*',
    class: 'destinations-map__me',
    coordElement: 'ME',
    visible: 1,
    link: 'https://flyer-club.com/p/region/middle-east/',
  },
];

export const initCircles = ({ mapElement }) => {
  if (mapElement) {
    circles.forEach((item) => {
      if (document.body.classList.contains('uk') && (item.id == 'SA' || item.id == 'EU')) {
        item.visible = 0;
      }

      if (item.visible == 1) {
        mapElement.insertAdjacentHTML(
          'afterbegin',
          `<a id="${item.id}" class="destinations-map__circle ${item.class}" href="${item.link}">
    <span>${item.country}</span>
        <p>from</p>
    <span>${item.price}</span>
</a>`
        );
        //setCircles(item);
      }
    });
  }
};

// function setCircles(circle)
// {
//     if(circle.visible!==1){
//         return;
//     }

//     const coordElement=circle['coordElement'];
//     const coordinates = document.querySelector(`#${coordElement}`).getBoundingClientRect();
//     const circleDOM=document.querySelector(`.${circle.class}`);
//     const section_map_coordinates=document.querySelector('.destinations-map').getBoundingClientRect();

//     circleDOM.style.right = Math.round(section_map_coordinates.right-coordinates.right)+'px';
//     circleDOM.style.bottom = Math.round(section_map_coordinates.bottom-coordinates.bottom)+'px';

// }
