import { debouncedInitOnResize } from 'src/assets/js/utils/utils';

export const initButtonsTrigger = ({ dialogElement, buttonSelector, debounceTime }) => {
  const triggerInitFlightDialog = ({ dialogElement }) => {
    const handleClick = () => dialogElement.show();
    const triggerButtons = document.querySelectorAll(buttonSelector);

    if (triggerButtons && triggerButtons.length) {
      triggerButtons.forEach((triggerButton) => {
        triggerButton.onclick = handleClick;
      });
    }
  };
  debouncedInitOnResize({
    initFunction: triggerInitFlightDialog,
    params: { dialogElement },
    debounceTime,
  });
};
