import { initForm } from 'src/assets/js/services/form/initForm.js';
import { initButtonsTrigger } from './initButtonsTrigger';
import { addedClassOnResize } from '../../utils/utils';

export const initDialogFreeQuote = ({ dialogFreeQuote }) => {
  if (dialogFreeQuote) {
    const closeButton = dialogFreeQuote.querySelector('.icon-menu');
    initButtonsTrigger({ dialogElement: dialogFreeQuote, debounceTime: 1000, buttonSelector: '.trigger_free-quote' });
    initForm({ formElement: dialogFreeQuote.querySelector('.form') });
    closeButton && closeButton.addEventListener('click', () => dialogFreeQuote.hide());

    addedClassOnResize({
      element: dialogFreeQuote,
    });
  }
};
