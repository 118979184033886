import { addedClassOnResize } from '../../utils/utils';

export const initDialogSuccess = ({ dialogSuccess }) => {
  if (dialogSuccess) {
    const closeButton = dialogSuccess.querySelector('.icon-menu');

    closeButton && closeButton.addEventListener('click', () => dialogSuccess.hide());
    addedClassOnResize({
      element: dialogSuccess,
    });
    // setTimeout(() => {
    //   dialogSuccess.show();
    // }, 1000);
  }
};
