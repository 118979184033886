import { initForm } from 'src/assets/js/services/form/initForm.js';
import { formTypes } from 'src/assets/js/helpers/constants';
import { initDialog } from 'src/assets/js/services/dialog/initDialog';
import { handleSearchInputInit } from 'src/assets/js/services/form/airportSearch/initAirportSearchForm';
import { initPassengerConfiguration } from '../../services/form/airportSearch/initFlightTicketForm';
import { gridBreakpoints } from '../../helpers/constants';

export const initPopupTrigger = ({ formElement, popupTriggerConfig }) => {
  Object.keys(popupTriggerConfig).forEach((key) => {
    const { dialogElement } = popupTriggerConfig[key];
    dialogElement.addEventListener('sl-hide', () => {
      document.body.classList.remove('show-popup');
    });
  });
  const callback = ({ elem }) => {
    if (elem) {
      Object.keys(popupTriggerConfig).forEach((key) => {
        const { dialogElement, inputClass } = popupTriggerConfig[key];

        if (elem.classList.contains(inputClass)) {
          dialogElement.show();
          document.body.classList.add('show-popup');
        }
      });
      window.clickedFormElement = elem.getAttribute('name');
    }
  };
  formElement.addEventListener('click', (e) => {
    callback({ elem: e.target });
  });
};
export const initFlightTicketForms = ({ flightTicketFormContainer, mainFormType }) => {
  if (flightTicketFormContainer) {
    const flightTicketForms = flightTicketFormContainer.querySelectorAll('sl-tab-panel > .form');
    const dropdowns = flightTicketFormContainer.querySelectorAll('.form__field-dropdown');
    const meetBtn = document.querySelector('sl-button.fix-meet');

    dropdowns?.forEach((dropdown) => {
      const formField = dropdown.querySelector('.form__field');
      // prevent double click
      formField.onclick = (e) => {
        if (meetBtn && innerWidth < 768) {
          meetBtn.classList.toggle('fix-meet_hide');
        }
        if (e.target.tagName === 'LABEL') {
          e.preventDefault();
        }
      };
    });
    const popupTriggerConfig = {
      // date: {
      //   dialogElement: null,
      //   inputClass: 'form__date-input',
      // },
      passenger: {
        dialogElement: null,
        inputClass: 'form__passenger-input',
      },
      departure: {
        dialogElement: null,
        inputClass: 'input_from',
      },
      return: {
        dialogElement: null,
        inputClass: 'input_to',
      },
    };
    if (flightTicketForms && flightTicketForms.length) {
      const isMainForm2 = mainFormType === formTypes.MAIN2;
      const isMobile = innerWidth < gridBreakpoints.xmd;
      if (isMainForm2 && isMobile) {
        // popupTriggerConfig.date.dialogElement = document.querySelector('.dialog-date');
        popupTriggerConfig.passenger.dialogElement = document.querySelector('.dialog-passenger');
        popupTriggerConfig.departure.dialogElement = document.querySelector('.dialog-departure');
        popupTriggerConfig.return.dialogElement = document.querySelector('.dialog-return');
        const searchCallback = ({ dialogElement }) => {
          if (dialogElement) {
            handleSearchInputInit({
              inputSearchElement: dialogElement.querySelector('.search-panel'),
              formType: formTypes.MAIN2,
            });
            // const form = dialogElement.querySelector('.flight-popup-form');
            // form?.addEventListener('submit', (e) => {
            //   e.preventDefault();
            //   return false;
            // });
          }
        };
        // initDialog({
        //   dialogElement: popupTriggerConfig.date.dialogElement,
        // });
        initDialog({
          dialogElement: popupTriggerConfig.passenger.dialogElement,
          callback: () => {
            initPassengerConfiguration({
              allPassengersFieldElement: popupTriggerConfig.passenger.dialogElement,
              formType: mainFormType,
            });
            const passengerButton = popupTriggerConfig.passenger.dialogElement.querySelector(
              '.flight-popup-form__passenger-button'
            );
            passengerButton?.addEventListener('click', () => {
              popupTriggerConfig.passenger.dialogElement.hide();
              // popupTriggerConfig.passenger.dialogElement
            });
          },
        });
        initDialog({
          dialogElement: popupTriggerConfig.departure.dialogElement,
          callback: () => {
            searchCallback({ dialogElement: popupTriggerConfig.departure.dialogElement });
          },
        });
        initDialog({
          dialogElement: popupTriggerConfig.return.dialogElement,
          callback: () => {
            searchCallback({ dialogElement: popupTriggerConfig.return.dialogElement });
          },
        });
      }
      for (let index = 0; index < flightTicketForms.length; index++) {
        const formElement = flightTicketForms[index];
        initForm({ formElement, formType: mainFormType });
        isMainForm2 && isMobile && initPopupTrigger({ formElement, popupTriggerConfig });
      }
    }
  }
};
