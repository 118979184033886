// import Swiper JS + pagination
import Swiper from 'swiper';
import { Pagination, Mousewheel } from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/mousewheel';
import 'swiper/css/pagination';

const initSlider = ({ sliderBlock }) => {
  const swiperElement = sliderBlock?.querySelector('.swiper');
  if (swiperElement) {
    const pagination = sliderBlock.querySelector('.swiper-pagination');
    // init Swiper:
    // eslint-disable-next-line no-unused-vars
    const swiper = new Swiper(swiperElement, {
      direction: 'horizontal', // Horizontal scrolling
      mousewheel: true, // Enable mouse wheel control
      pagination: {
        el: pagination,
        clickable: true,
      },
      // Default parameters
      // configure Swiper to use modules
      modules: [Pagination, Mousewheel],

      // slidesPerView: 'auto',
      spaceBetween: 20,
    });
  }
};

export const initBenefitsSlider = ({ sliderBlock }) => {
  initSlider({
    sliderBlock,
  });
};
