export const blurHeader = () => {
  // const burgerButtons = document.querySelectorAll('.icon-menu');
  //   const setStickyBurger = (pos) => {
  //   burgerButtons.forEach((burgerButton) => {
  //     burgerButton.style.transform = `translateY(${pos > preheaderHeight[currentBreakpoint] ? 0 : preheaderHeight[currentBreakpoint] - pos}px)`;
  //   });
  // };
  // window.lastKnownScrollPosition = 0;
  // window.ticking = false;

  // // call on init
  // setStickyBurger(window.scrollY);
  const header = document.querySelector('header.header');
  if (header) {
    const scrollHandle = () => {
      window.lastKnownScrollPosition = window.scrollY;

      if (!window.ticking) {
        window.requestAnimationFrame(() => {
          if (window.scrollY === 0) {
            header.classList.remove('header_shadow');
          } else {
            header.classList.add('header_shadow');
          }
          window.ticking = false;
        });

        window.ticking = true;
      }
    };
    scrollHandle();
    addEventListener('scroll', scrollHandle);
  }
};
