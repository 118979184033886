import { initReviews } from 'src/assets/js/services/sliders/initReviews.js';
import { initBurger } from 'src/assets/js/services/dialog/initBurger.js';
import { initLazyLoadImage } from 'src/assets/js/services/initLazyLoadImage.js';
import { initForm } from 'src/assets/js/services/form/initForm.js';
import { initHideCards } from 'src/assets/js/services/initHideCards.js';
import { initDialogFreeQuote } from 'src/assets/js/services/dialog/initDialogFreeQuote.js';
import { initDialogClassUp } from 'src/assets/js/services/dialog/initDialogClassUp.js';
import { initDialogSuccess } from 'src/assets/js/services/dialog/initDialogSuccess.js';
import { initDestinationsSlider } from 'src/assets/js/services/sliders/initDestinationsSlider.js';
import { initKeyAdvantagesSlider } from 'src/assets/js/services/sliders/initKeyAdvantagesSlider.js';
import { initPartnerSlider } from 'src/assets/js/services/sliders/initPartnerSlider';
import { initFlightTicketForms } from 'src/assets/js/layouts/forms/initFlightTicketForms.js';

import { fadeOut, preloader } from 'src/assets/js/services/initPreloader.js';
import { blurHeader } from 'src/assets/js/services/animations/blurHeader';
import { setUtmToLS } from 'src/assets/js/services/setUtmToLS';
import { setRedirectToElements, debouncedInitOnResize } from 'src/assets/js/utils/utils';
import { initYoutubeLoader } from 'src/assets/js/services/initYoutubeLoader';
import { setTabSwitching } from 'src/assets/js/helpers/helpers';
import { initCircles } from 'src/assets/js/services/initCircles';
import { initBenefitsSlider } from 'src/assets/js/services/sliders/initBenefitsSlider';

import { getReviewsSetScore } from 'src/assets/js/services/getReviewsSetScore';
import { trustScoreUrl } from 'src/assets/js/helpers/constants';
import { initAddBackgroundWrapper } from './initAddBackgroundWrapper';
import { initTimer } from '../initTimer';
import { formTypes } from '../../helpers/constants';
import { updatePhoneAfterUtm } from '../setUtmToLS';

export const initBase = async ({
  isInitTimer = true,
  isPreloader = true,
  isBlurHeader = true,
  isSetUtmToLS = true,
  isInitBurger = true,
  isInitLazyLoadImage = true,
  isInitDialogFreeQuote = true,
  iaInitDialogClassUp = true,
  iaInitDialogSuccess = true,
  isHeroTrustScore = true,
  isAddBackgroundWrapper = true,
  redirectToElements = [
    {
      selector: '.link_reviews',
      url: '#reviews',
      isHash: true,
      callback: () => {
        const drawerBurger = document.querySelector('.drawer-burger');
        drawerBurger?.hasAttribute('open') && drawerBurger.hide();
      },
    },
  ],
  heroInitType = 1,
  mainFormType = formTypes.MAIN,
  isInitReviews = false,
  isInitDestinationsSlider = false,
  isInitHideCards = false,
  isInitKeyAdvantagesSlider = false,
  isInitBenefitsSlider = false,
  isInitFormContacts = false,
  isInitFormSubscribe = false,
  isInitPartnerSlider = false,
  isInitServiceSteps = false,
  isInitStorySteps = false,
  isInitFlightTicketForms = false,
  isInitYoutubeLoader = false,
  isInitCircles = false,
}) => {
  isInitTimer &&
    initTimer({
      timerElement: document.querySelector('.timer'),
    });
  const heroSection = document.querySelector('.hero');
  let reviews;

  isPreloader && (await fadeOut(preloader));
  isBlurHeader && blurHeader();
  isSetUtmToLS && setUtmToLS();
  updatePhoneAfterUtm();
  isInitBurger && (await initBurger());
  isInitLazyLoadImage && initLazyLoadImage();
  redirectToElements?.length &&
    setRedirectToElements({
      elements: [
        {
          selector: '.link_reviews',
          url: '#reviews',
          isHash: true,
          callback: () => {
            const drawerBurger = document.querySelector('.drawer-burger');
            drawerBurger?.hasAttribute('open') && drawerBurger.hide();
          },
        },
      ],
    });

  if (heroSection) {
    const parallaxBgWrap = document.querySelector('.parallax-bg__wrap');
    // set height of container for fixed hero parallaxBg
    if (parallaxBgWrap) {
      const parallaxBg = parallaxBgWrap.querySelector('.parallax-bg');

      if (parallaxBg) {
        const setHeight = () => {
          setTimeout(() => {
            parallaxBg.style.height = `${parallaxBgWrap.offsetHeight}px`;
          }, 10);
        };
        debouncedInitOnResize({ initFunction: setHeight });
      }
    }
    if (heroInitType) {
      let initHero;
      switch (heroInitType) {
        case 1:
          initHero = (await import('src/assets/js/services/base/initHeroBlocks.js'))?.initHero1;
          break;
        case 2:
          initHero = (await import('src/assets/js/services/base/initHeroBlocks.js'))?.initHero2;
          break;
        default:
          break;
      }
      initHero && initHero({ heroSection });
    }
  }

  isInitFlightTicketForms &&
    initFlightTicketForms({
      flightTicketFormContainer: document.querySelector('.find-ticket__form-container'),
      mainFormType,
    });
  isInitDestinationsSlider &&
    initDestinationsSlider({
      sliderBlock: document.querySelector('.destinations .slider'),
    });
  isInitBenefitsSlider &&
    initBenefitsSlider({
      sliderBlock: document.querySelector('.benefits-slider .slider'),
    });

  isInitHideCards &&
    setTimeout(() => {
      initHideCards();
    }, 800);

  isInitKeyAdvantagesSlider &&
    initKeyAdvantagesSlider({
      sliderBlock: document.querySelector('.key-advantages .slider'),
    });

  isInitFormContacts && initForm({ formElement: document.querySelector('.contacts__form, .contact-us__form') });
  isInitFormSubscribe && initForm({ formElement: document.querySelector('.subscribe__form') });

  isInitPartnerSlider &&
    initPartnerSlider({
      sliderElement: document.querySelector('.partners-slider .swiper'),
    });
  isInitServiceSteps &&
    setTabSwitching({
      container: document.querySelector('.service-steps'),
    });
  isInitStorySteps &&
    setTabSwitching({
      container: document.querySelector('.story-steps'),
    });

  isInitYoutubeLoader && initYoutubeLoader();
  isInitDialogFreeQuote && initDialogFreeQuote({ dialogFreeQuote: document.querySelector('.dialog-free-quote') });
  iaInitDialogClassUp &&
    initDialogClassUp({
      dialogClassUpElement: document.querySelector('.dialog-classup'),
    });
  iaInitDialogSuccess &&
    initDialogSuccess({
      dialogSuccess: document.querySelector('.dialog-success'),
    });
  isInitCircles &&
    initCircles({
      mapElement: document.querySelector('.destinations-map__map'),
    });

  isAddBackgroundWrapper && initAddBackgroundWrapper();
  if (isHeroTrustScore) {
    reviews = await getReviewsSetScore();
  }
  isInitReviews &&
    initReviews({
      url: trustScoreUrl,
      reviews,
    });
  changeAnchorsBehaviour();
};

const changeAnchorsBehaviour = () => {
  setTimeout(() => {
    const anchors = document.querySelectorAll('*[href^="#"]');
    const headerOffsetHeight = document.querySelector('.header')?.offsetHeight;

    anchors.forEach((anchor) => {
      const href = anchor.getAttribute('href');
      const element = document.querySelector(href == '#' ? 'body' : href);
      if (element) {
        const top = element.offsetTop > headerOffsetHeight ? element.offsetTop - headerOffsetHeight * 2 : element.offsetTop;
        anchor.addEventListener('click', (e) => {
          e.preventDefault();
          window.scrollTo({
            top,
            left: 0,
            behavior: 'smooth',
          });
          return false;
        });
      }
    });
  }, 1000);
};
