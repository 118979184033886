// import Swiper JS + navigation
import Swiper from 'swiper';
// import Swiper and modules styles
import 'swiper/css';

export const initPartnerSlider = ({ sliderElement }) => {
  if (sliderElement) {
    // init Swiper:
    // eslint-disable-next-line no-unused-vars
    const swiper = new Swiper(sliderElement, {
      // Default parameters

      slidesPerView: 'auto',
      spaceBetween: 12,
      // Responsive breakpoints
      breakpoints: {
        // when window width is >= 768px
        768: {
          spaceBetween: 27,
        },
        // when window width is >= 1024px
        1024: {
          spaceBetween: 60,
        },
        // // when window width is >= 1440px
        // 1440: {
        //   spaceBetween: 16,
        // },
        // // when window width is >= 1920px
        // 1920: {
        //   slidesPerView: 6,
        // },
      },
    });
  }
};
