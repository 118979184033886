import { gridBreakpoints } from 'src/assets/js/helpers/constants';
import { bgAddTriggerTypes } from '../../helpers/constants';

export const initAddBackgroundWrapper = () => {
  const utmSourceParam = window.sprintfulWidgetUTM ? `utm_source=${window.sprintfulWidgetUTM}` : '';
  const popupUrl = `https://on.sprintful.com/select-date?hide-logo=false&hide-message=false&show-close=false${utmSourceParam ? '&' + utmSourceParam : ''}`;
  const arrElements = [
    {
      selector: '.fix-call-me',
      type: bgAddTriggerTypes.SLIDED_PHONE,
    },
    {
      selector: '.fix-meet',
      type: bgAddTriggerTypes.SPRINTFUL,
    },
    {
      selector: '.schedule-call',
      type: bgAddTriggerTypes.SPRINTFUL,
    },
    {
      selector: '.fix-time',
      type: bgAddTriggerTypes.SPRINTFUL,
      callback: ({ element }) => {
        utmSourceParam && element.setAttribute('href', `https://on.sprintful.com/select-date?${utmSourceParam}`);
      },
    },
  ];
  const bgElem = document.createElement('div');

  bgElem.setAttribute('tabindex', 999);
  bgElem.classList.add('background-block');
  document.body.appendChild(bgElem);

  const handlePopupClick = () => {
    if (typeof Sprintful !== 'undefined') {
      // eslint-disable-next-line no-undef
      Sprintful.showPopup({
        url: popupUrl,
        copyParentsQuery: 'false',
      });
    }
    return false;
  };
  arrElements.forEach(({ selector, type, callback }) => {
    const element = document.querySelector(selector);
    if (element) {
      switch (type) {
        case bgAddTriggerTypes.SPRINTFUL:
          element.onclick = handlePopupClick;
          setTimeout(() => {
            element.style.opacity = 1;
            element.style.scale = 1;
          }, 1000);
          break;
        case bgAddTriggerTypes.SLIDED_PHONE:
          setTimeout(() => {
            element.style.transform = 'translateX(0)';
          }, 1000);
          if (innerWidth < gridBreakpoints['xmd']) {
            element.addEventListener('click', (e) => {
              const btn = e.currentTarget;

              if (!bgElem.classList.contains('background-block_active')) {
                e.preventDefault();
                bgElem.classList.add('background-block_active');
                btn.classList.add('fix-call-me_active');
                btn.focus();
              } else {
                bgElem.classList.remove('background-block_active');
                btn.classList.remove('fix-call-me_active');
              }
            });
            element.addEventListener('blur', (e) => {
              const btn = e.currentTarget;

              if (e.relatedTarget) {
                bgElem.classList.remove('background-block_active');
                btn.classList.remove('fix-call-me_active');
              }
            });
          }
          break;

        default:
          break;
      }
      callback && callback({ element });
    }
  });
};
