import drawerBurgerTemplate from 'src/templates/layouts/drawerContent/drawerBurger.ejs';
import menuTemplate from 'src/templates/components/menu/menu.ejs';
import menuItemTemplate from 'src/templates/components/menu/menuItem.ejs';
import socialsTemplate from 'src/templates/layouts/socials.ejs';
import menuButtonTemplate from 'src/templates/components/button/slButton.ejs';
import burgerButtonTemplate from 'src/templates/layouts/burgerButton.ejs';
import drawerTemplate from 'src/templates/components/drawer/drawer.ejs';
import {
  gridBreakpoints,
  insertPosition,
  preheaderHeight,
  preheaderBlackFridayHeight,
  preheaderNewYearHeight,
  preheaderSpringHeight,
} from 'src/assets/js/helpers/constants';
import { pasteByInsertPosition } from 'src/assets/js/helpers/helpers';
import { debouncedInitOnResize } from 'src/assets/js/utils/utils';
import { getCurrentBreakpoint } from '../../helpers/helpers';

export const initBurger = async () => {
  let isInitialized = false;
  const isPermanentBurger = document.body.classList.contains('permanent-burger');
  const initBurgerDrawer = async () => {
    if (!isInitialized && (innerWidth <= gridBreakpoints.xlg || isPermanentBurger)) {
      const drawerClasses = 'drawer-burger';
      const burgerClasses = 'icon-menu';

      const drawerLayout = await drawerTemplate({
        classes: drawerClasses,
        content: drawerBurgerTemplate({
          menuButton: menuButtonTemplate({
            classes: 'btn-sm btn-primary trigger_free-quote',
            content: 'Get a Quote',
          }),
          menu: menuTemplate({
            classes: 'drawer-burger__menu-list',
            items: [
              menuItemTemplate({
                link: {
                  text: 'About us',
                  href: '/aboutus/',
                  classes: 'link',
                },
              }),
              menuItemTemplate({
                link: {
                  text: 'Destinations',
                  href: '/p/bestdeals/',
                  classes: 'link link_active',
                },
              }),
              menuItemTemplate({
                link: {
                  text: 'How to book',
                  href: 'https://flyer-club.com/booking/',
                  classes: 'link',
                },
              }),
              menuItemTemplate({
                link: {
                  text: 'Faq',
                  href: '/faq/',
                  classes: 'link',
                },
              }),
              menuItemTemplate({
                link: {
                  text: 'Reviews',
                  href: '#reviews',
                  classes: 'link link_reviews',
                },
              }),
              menuItemTemplate({
                link: {
                  text: 'Contacts',
                  href: '/contacts/',
                  classes: 'link',
                },
              }),
            ],
          }),
          socials: socialsTemplate(),
        }),
      });
      const burgerButtonLayout = burgerButtonTemplate({
        classes: burgerClasses,
      });

      pasteByInsertPosition({
        insertPositionType: insertPosition.afterend,
        parentElement: document.body.querySelector('& > header'),
        child: burgerButtonLayout,
      });

      const burgerButtons = document.querySelectorAll('.icon-menu');

      pasteByInsertPosition({
        insertPositionType: insertPosition.beforeend,
        parentElement: document.body,
        child: drawerLayout,
      });

      const drawer = document.querySelector(`.${drawerClasses}`);

      if (document.body.querySelector('.preheader')) {
        const currentBreakpoint = getCurrentBreakpoint();
        let currentPreheaderHeight;
        if (document.body.classList.contains('black-friday')) {
          currentPreheaderHeight = preheaderBlackFridayHeight[currentBreakpoint];
        } else if (document.body.classList.contains('new-year')) {
          currentPreheaderHeight = preheaderNewYearHeight[currentBreakpoint];
        } else if (document.body.classList.contains('spring')) {
          currentPreheaderHeight = preheaderSpringHeight[currentBreakpoint];
        } else {
          currentPreheaderHeight = preheaderHeight[currentBreakpoint];
        }
        const setStickyBurger = (pos) => {
          burgerButtons.forEach((burgerButton) => {
            burgerButton.style.transform = `translateY(${pos > currentPreheaderHeight ? 0 : currentPreheaderHeight - pos}px)`;
          });
        };
        window.lastKnownScrollPosition = 0;
        let ticking = false;

        // call on init
        setStickyBurger(window.scrollY);
        addEventListener('scroll', () => {
          window.lastKnownScrollPosition = window.scrollY;

          if (!ticking) {
            window.requestAnimationFrame(() => {
              setStickyBurger(window.lastKnownScrollPosition);
              ticking = false;
            });

            ticking = true;
          }
        });
      }
      burgerButtons[0].addEventListener('click', () => (drawer.hasAttribute('open') ? drawer.hide() : drawer.show()));
      drawer.addEventListener('sl-show', () => {
        // if (window.lastKnownScrollPosition < preheaderHeight[currentBreakpoint]) {
        //   window.scrollTo({
        //     top: preheaderHeight[currentBreakpoint],
        //     left: 0,
        //     behavior: 'smooth',
        //   });
        // }
        burgerButtons[0].classList.add('icon-menu_open');
      });
      drawer.addEventListener('sl-hide', () => {
        burgerButtons[0].classList.remove('icon-menu_open');
      });

      isInitialized = true;
    }
  };

  isPermanentBurger
    ? await debouncedInitOnResize({
        initFunction: initBurgerDrawer,
      })
    : await initBurgerDrawer();
};
